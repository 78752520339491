import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import MasterCourseForm from "../../components/masterCourseForm"
import ImgTriangle from "../../../static/images/course/triangle.svg"
import HeaderImg from "../../../static/images/course/master-course-header.png"
import icon1 from "../../../static/images/course/icon1.svg"

const MasterCourse = () => {
    return (
        <div>
            <Seo title="仕事につながるマスターコース" noindex={true} />
            <div><Link to="/business"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>

            <div className="masterCourse">
                <div className="mc-block1">
                    <img src={HeaderImg} className="header-img"/>
                    <h2>仕事につながるマスターコース<br/>【限定】</h2>
                    <h4>多数のビギナーをReact入門に成功させた現役エンジニアが、スタートから就職・転職・案件獲得までの包括的サポートを提供する、完全オーダーメイドのサポートコース。</h4>
                </div>
                <div className="mc-block2">
                    <img src={icon1}/>
                    <h4>独学でプログラミングを学び、就職・転職をしたり、フリーランス案件の獲得をしたりすることは可能です。しかし挫折率が非常に高いのもこの独学ルート。その原因は次の3つです。</h4>
                    <div className="center">
                        <hr/>
                        <p>🔺「理解できない」「専門用語が多すぎる」「正しいコードが書けているのかわからない」が続き、自信とモチベーションが低下🔺</p>
                        <p>🔺「学ぶべき学習範囲」がわからずに時間を浪費。あるいは「必要な学習範囲」を広く見積りすぎて、その量に圧倒される🔺</p>
                        <p>🔺 目の前の「勉強」と、就職・案件獲得という「ゴール」がどうつながっているのかイメージを描けず、勉強が続けられなくなる🔺</p>
                        <hr/>
                    </div>
                </div>
                <div className="mc-block2-5">
                    <h4>独学者がつまづくこれらのポイントを乗り越えるため、本コースでは「必須スキル」、「パーソナル・メンタリング」、「ポートフォリオ制作サポート」の3点を柱として、ビギナーを就職・転職・案件獲得というゴールまでスムーズに導きます。</h4>
                    <img src={ImgTriangle}/>
                </div>
                <div className="mc-block3">
                    <h3>１／必須スキル<br/>
                    <span>多数のビギナーからのフィードバックを活かした教材で、2024年の開発現場で必須の知識を身につけます。</span>
                    </h3>
                    <p><span>🔹 React</span>：採用数ナンバーワンのフロントエンド・フレームワーク</p>
                    <p><span>🔹 TypeScript</span>：React開発で導入がデフォルトになっているJavaScriptの上位互換言語</p>
                    <p><span>🔹 Next.js</span>：React開発のスターターキットとして採用件数が右肩上がりのReactフレームワーク</p>
                    <p><span>🔹 バックエンドスキル</span>：Node.js、Express</p>
                    <p><span>🔹 インフラ／データベーススキル</span>：MongoDB、Firebase、Vercel、Netlifyなど</p>

                    <h3>２／パーソナル・メンタリング<br/>
                    <span>挫折を防止し、最短距離でゴールに導くためのきめ細やかなサポート</span>
                    </h3>
                    <p><span>🔹 時間・回数無制限</span>：学習中の疑問や不明点はチャットで質問し放題</p>
                    <p><span>🔹 定期ミーティング</span>：正しい方向へ向かって進めているか確かめる軌道修正のためのガイダンス</p>
                    <p><span>🔹 コードレビュー</span>：単なる「動くコード」「自己流のコード」ではなく、より正しいコード、より開発現場で使われているコードを指導</p>
                    <p><span>🔹 学習ロードマップの作成</span>：ゴール達成までの最短ルートを示す、オーダーメイドの学習マップ</p>
                    <p><span>🔹 学習時の悩み相談</span>：孤独な独学の中で浮かぶ悩みを解決</p>

                    <h3>３／ポートフォリオ制作フルサポート<br/>
                    <span>エンジニアの就職・案件獲得で必須のポートフォリオ制作をゼロからサポート</span>
                    </h3>
                    <p><span>🔹 制作支援</span>：ポートフォリオのデザイン・設計・開発をゼロからサポート</p>
                    <p><span>🔹 履歴書の作成サポート</span>：応募に最適なオフライン／オンライン双方の履歴書作成をお手伝い</p>
                    <p><span>🔹 志望動機書の添削</span>：豊富な執筆経験をもとに、説得力ある文章を書くためのアドバイス</p>
                </div>
                <div className="mc-block4">
                    <h3>🟩 独学時代の後悔</h3>
                    <p>私（三好）は、大学卒業後はITとは無関係の業界にいました。新卒でIT企業に入ったのでも、プログラミングスクールに通ったのでもありません。エンジニアには独学でなりました。</p>
                    <p>だからこそ、独学という勉強法のメリットとデメリットを知っています。メリットは、なんといってもお金がかからないことでしょう。自分のペースで進められることも大きな魅力です。<br/>その一方で独学にはデメリットもあります。モチベーション維持の難しさと時間を浪費することです。<br/>「確かにコードは動くけど、これが正しいのかわからない」といった不安は常にありましたし、「createがcreteになっている」という単純すぎるミスの発見に丸1日ついやすこともありました。</p>
                    <p>独学時代をふり返ると、まわり道がたくさんありました。「それもすべていい思い出だ」と肯定してもいいですが、人生で取り返しのつかないもの、つまり「時間」というものを考えたときには、これら回り道の連続には後悔を強く感じます。</p>
                    <p>もし今の状態で過去に戻れるなら、必要なことだけをセレクトして効率的に学び、はるかに短時間で、転職や案件獲得といった当時の私のゴールを実現するでしょう。</p>
                    <p>本コースは私自身の経験に基づいて、ビギナーを最短距離でゴールまで導くコースです</p>
                </div>
                <div className="mc-block5">
                    <h3>🟩 三好アキについて</h3>
                    <div>
                        <StaticImage src="../../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                        <p className="name">三好アキ（エンジニア／執筆家／講師）</p>
                        <p>🔶 Amazonベストセラー1位を複数回獲得している『はじめてつくるReactアプリ with TypeScript』著者。</p>
                        <p>🔶 自身のJavaScript挫折経験をもとに、HTMLとCSSの知識だけで本格的なアプリ開発を始められる入門書を多数執筆中。合計著作は22冊を超える。</p>
                        <p>🔶 専門用語なしでプログラミングを教えることに定評があり、1200人以上のビギナーを、最新のフロントエンド開発入門に成功させる。</p>
                    </div>
                </div>
                <div className="mc-block6">
                    <h3>🟩 独学が難しい3大原因</h3>
                    <h4>１／「わからない」が続いてモチベーション低下</h4>
                    <p>🔶 頭の中で「わからない」の量が一定レベルに達すると、私たちの手は止まります。不安で続けられなくなるのです。<br/>「専門用語がわからない」<br/>「たしかに動くけど、正しいコードなのかはわからない」<br/>「自分のしている勉強法が正しいのかわからない」……</p>
                    <h4>２／勉強すべき範囲がわからず、勉強自体が目的化</h4>
                    <p>🔶 ネットで調べればいくらでも学習教材は出てきます。そしてひとつ教材を終わらせれば、達成感を味わえてしまいます。<br/>しかしそのような「練習」を繰り返しているだけで、転職や案件獲得といった「ゴール」が達成されるのかというと……
                    </p>
                    <h4>３／ゴールまでの道のりがイメージできない</h4>
                    <p>🔶 目の前の「勉強」と、就職・案件獲得という未来の「ゴール」がどうつながっているのか、どうすれば到達できるのか見通すこと……これが独学で特に難しい点です。<br/>そしてこれを乗り越えられない時に起きることは、「前に進めなくなる」、もしくは「勉強が目的化する」という事態です。</p>
                </div>
                <div className="mc-block6">
                    <h3>🟩 本コースの特徴</h3>
                    <h4>１／あらゆる疑問をすぐに解決するパーソナルなメンタリング</h4>
                    <h4>２／ゴール達成のための必要な学習項目だけをピックアップした、過不足のない効率的な学習カリキュラム</h4>
                    <h4>３／「プログラミングスキルを身につける」という実務面での支援と、メンタリングという心理面での支援。これら両面でのサポートによってゴール達成を実現</h4>
                </div>
                <div className="mc-block7">
                    <h3>🟩 定員</h3>
                    <p>ひとりひとりの性格、目的、ゴールに合うサポートを提供するため、対応可能人数には限りがあります。まずは本ページ下部の連絡フォームからお問い合わせください。</p>
                </div>
                <div className="mc-block8">
                    <h3>🟩 本コースの対象者</h3>
                    <p>✦ HTMLとCSSでウェブサイトを作る知識をお持ちの方（JavaScriptの知識は不要です）</p>
                    <p>✦ 勉強時間を十分確保できる方</p>
                </div>
                <div className="mc-block9">
                    <h3>🟩 よくいただく質問</h3>
                    <p>🔶 なぜ限定コースなのですか？</p>
                    <p>→ マニュアルに沿った画一的サービスを提供するものではないためです。三好アキがひとりひとりに個別的なサポートを行うため、限定となっています。</p>
                    <p>🔶 オフライン（教室）でのコースですか？</p>
                    <p>→ 完全オンラインのコースです。在宅で進められます。</p>
                    <p>🔶 だれが指導・メンタリングをしますか？</p>
                    <p>→ 三好アキが直接いたします</p>
                    <p>🔶 このコースを始める前の条件はありますか？</p>
                    <p>→ HTML／CSSの知識と経験は必要です。またプログラミング学習のための時間を十分確保できることも条件です。</p>
                    <p>🔶 対応人数が限られている理由は？</p>
                    <p>→ 本コースの根幹は、ひとりひとり異なるニーズをヒアリングし、それを達成するための最適な方法をオーダーメイドでご提供することです。そのため、ごく少数の方のみへのご提供となっています。もしニーズのヒアリング時にご支援が難しいと明らかになった場合には、そのようにお伝えいたします。</p>
                    <p>🔶 もっとくわしく知りたい</p>
                    <p>→ まずは下記フォームよりお問い合わせください。</p>
                </div>
                <div className="mc-block10">
                    <h3>🟩 お問い合わせ</h3>
                    <p>本コースは対応可能な人数が非常に限られるため、まずは下記フォームよりご連絡ください。</p>
                    <p>その際、お名前と現在のスキル（プログラミング関係、その他）、そして達成したいゴールもお知らせください。</p>
                    <MasterCourseForm/>
                </div>
            </div>
        </div>
    )
}

export default MasterCourse


/* *お知らせ（2024年2月）

本書を最後の最後までお読みいただきありがとうございます。

これまでの経験では、Reactの学習を始める方の9割以上は、勉強の理由として以下のことを挙げます。

✤ Reactを身につけて就職したい\
✤ フリーランス案件に参加したい\
✤ 週3労働で50万円稼ぎたい

就職、案件獲得を目的としたものです。これは本書初版を出版した2021年から今日の2024年に至るまで変わりませんし、私自身、プログラミングの勉強を始めたときの動機の中で、「就職」「仕事」が大きなポジションを占めていたのを覚えています。

しかし未経験から案件獲得／就職といったゴールまで、ひとりで到達するのは難しいことも事実です。そのため、未経験者がフロントエンドを中心とした総合的なウェブアプリケーション開発スキルを身につけ、最初の案件、あるいは就職先獲得までをサポートするサービスを私は提供しています。

このサービスは、私が個別的にゴールまでの道のりを徹底サポートするもので、対応できる人数が非常に限られるため、ご興味のある方は下記メールアドレスまで直接ご連絡ください。くわしい内容をお伝えいたします。

なおメールにはお名前と現在のプログラミングスキル、そして達成したいゴールを書いてください。

<a href="mailto:contact@monotein.com">contact@monotein.com</a> */