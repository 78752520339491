import React, { useState } from 'react'

const MasterCourseForm = () => {    
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [sendDone, setSendDone] = useState(false)
    const [sendingText, setSendingText] = useState("処理を実行しています...")

    const callFetch = () => {                                                                     
        fetch("https://us-central1-nov182021.cloudfunctions.net/server/master-course-form", {         //http://localhost:5001/nov182021/us-central1/server/mail/download-materials
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: name, email: email, message: message })
        })
        .then(res => res.json())
        .then(data => {
            console.log("res.send:data::::", data)
            setSendDone(true)
        })
        .catch(err => alert("エラーが発生しました。ページをリロードして、もう一度トライしてください。", err)) 
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        callFetch()
        setTimeout(() => {
            setSendingText("送信処理を起動中です...")
        }, 3100) 
        setTimeout(() => {
            setSendingText("処理を実行中です...") 
        }, 6000) 
    }
  
    const handleNameChange = (e) => {
        setName(e.currentTarget.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.currentTarget.value)
    }

    const handleMessageChange = (e) => {
        setMessage(e.currentTarget.value)
    } 

    return (
        <>
            {
            sendDone ? 
                <h3 style={{textAlign: "center"}}>ありがとうございます。数日中にご連絡いたします。</h3>
            :
            <form onSubmit={handleSubmit}>
                <div>
                    <input onChange={handleNameChange} value={name} placeholder="*お名前" name="name" type="text" required/>
                    <input onChange={handleEmailChange} value={email} placeholder="*メールアドレス" name="email" type="text" required/>
                    <textarea name="message" onChange={handleMessageChange} rows="5" id="textarea" placeholder="*現在のスキルと、達成したいゴール（転職・フリーランス案件獲得など）をご記入ください"></textarea>
                    <button type="submit" disabled={loading}>
                        {loading ? 
                            <>{sendingText}</>
                            :
                            <><span>送信</span></>
                        }
                    </button>
                </div>
            </form>
            }
        </>
    )
}

export default MasterCourseForm